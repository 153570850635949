import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Link } from '@mui/material';

const LogoSection = () => (
	<Link component={RouterLink} to={'/dashboard'} underline="none">
		<Typography variant="h2" align="center">
			Christus Health
		</Typography>
	</Link>
);

export default LogoSection;
